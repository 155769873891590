<template>
  <div>
    <!-- Filters -->
    <filters :tenant-filter.sync="tenantFilter" :tenant-options="tenantOptions"/>

    <!-- Table Container Card -->
    <b-card class="m-2" no-body>
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label class="text-capitalize">{{ $t("table.show") }}</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label class="text-capitalize">{{ $t("table.entries") }}</label>
          </b-col>

          <!-- Search -->
          <b-col cols="12" md="6">
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                :placeholder="$t('table.search') + '...'"
              />
              <b-button
                variant="secondary"
                to="/video-watermarks/config"
                v-if="$can('create', 'VideoWaterMark')"
              >
                <span class="text-nowrap text-capitalize">{{
                    $t("actions.add") + " watermark"
                  }}</span>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>

      <b-table
        ref="refListTable"
        class="position-relative"
        :items="fetchList"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        :empty-text="$t('no_record_found')"
        :sort-desc.sync="isSortDirDesc"
      >
        <template #head()="data">
          <span> {{ data.label }}</span>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown variant="link" no-caret :right="$store.state.appConfig.isRTL">
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>

            <b-dropdown-item
              :to="`video-watermarks/config/${data.item.id}`"
              v-if="$can('update', 'VideoWaterMark')"
            >
              <feather-icon icon="EditIcon"/>
              <span class="align-middle ml-50 text-capitalize">{{
                  $t("actions.edit")
                }}</span>
            </b-dropdown-item>

            <b-dropdown-item @click="confirmDelete(data.item.id)"
                             v-if="$can('delete', 'VideoWaterMark')">
              <feather-icon icon="TrashIcon"/>
              <span class="align-middle ml-50 text-capitalize">{{
                  $t("actions.delete")
                }}</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted text-capitalize">{{
                $t("table.showing", {
                  from: dataMeta.from,
                  to: dataMeta.to,
                  of: dataMeta.of,
                })
              }}</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <Pagination
              :totalList="totalList"
              :currentPage="currentPage"
              @updatePagination="update"
              :perPage="perPage"
            >
            </Pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BDropdown,
  BDropdownItem,
  BTable,
  BPagination,
} from "bootstrap-vue";
import {useToast} from "vue-toastification/composition";
import ToastificationContent
  from "@core/components/toastification/ToastificationContent.vue";
import {useUtils as useI18nUtils} from "@core/libs/i18n";
import vSelect from "vue-select";
import Filters from "./Filters.vue";
import useList from "./useList";
import Swal from "sweetalert2";
import axios from '@axios';
import Pagination from "@/components/Pagination.vue";

export default {
  components: {
    Filters,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BDropdown,
    BDropdownItem,
    vSelect,
    BTable,
    BPagination,
    Pagination
  },

  setup(_, context) {
    //start pagination
    const update = (data) => {
      currentPage.value = data;
    };
    //end pagination

    const {
      fetchList,
      tableColumns,
      perPage,
      currentPage,
      totalList,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refListTable,
      refetchData,
      formatDateAssigned,
      tenantOptions,
      tenantFilter,
    } = useList();
    const toast = useToast();

    const confirmDelete = (id) => {
      Swal.fire({
        title: "¿Estás seguro de elimar la marca de agua?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Eliminar",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {

          axios.delete(`v1/services/video/watermark/${id}`).then(() => {
            refetchData();
            toast({
              component: ToastificationContent,
              props: {
                title: "Marca de agua eliminada correctamente",
                icon: "CheckIcon",
                variant: "success",
              },
            });
          });
        }
      });
    };

    return {
      searchQuery,
      update,
      fetchList,
      tableColumns,
      perPage,
      currentPage,
      totalList,
      dataMeta,
      perPageOptions,
      sortBy,
      isSortDirDesc,
      refListTable,
      refetchData,
      formatDateAssigned,
      tenantOptions,
      tenantFilter,
      confirmDelete,
    };
  },
};
</script>